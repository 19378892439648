import React from "react";
import qore from "../../context/qoreContext";
import { Box, Container, Text, SimpleGrid, Image, Center, useBoolean } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Skeleton from "react-loading-skeleton";

import {
  Pagination,
  usePagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup
} from "@ajna/pagination";

import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";

import "react-loading-skeleton/dist/skeleton.css";

const BeautyTips = () => {
  const [contentsTotal, setContentsTotal] = React.useState(0);
  const [contentList, setContentList] = React.useState([]);
  const [loading, setLoading] = useBoolean(true);
  const outerLimit = 2;
  const innerLimit = 2;
  const skeletonCount = [1, 2, 3, 4, 5, 6, 7, 8];

  const fetchData = async offset => {
    try {
      setLoading.on();
      const {
        data: { nodes }
      } = await qore.client.project.axios.get(`/publishedTips/rows`, {
        params: {
          limit: 8,
          offset,
          "$by.index": "asc"
        }
      });

      if (!nodes.length) throw false;
      setContentList(nodes);
      setLoading.off();
    } catch (error) {
      console.error(error);
      setLoading.off();
    }
  };

  const fetchTotalCount = async () => {
    try {
      setLoading.on();
      const {
        data: { totalCount }
      } = await qore.client.project.axios.get(`/publishedTips/rows/count`);

      setContentsTotal(totalCount);
    } catch (error) {
      console.error(error);
      setLoading.off();
    }
  };

  const { pages, pagesCount, offset, currentPage, setCurrentPage, pageSize } = usePagination({
    total: contentsTotal,
    limits: {
      outer: outerLimit,
      inner: innerLimit
    },
    initialState: {
      pageSize: 8,
      isDisabled: false,
      currentPage: 1
    }
  });

  React.useEffect(() => {
    fetchData(offset);
  }, [currentPage, pageSize, offset]);

  React.useEffect(() => {
    fetchTotalCount();
  }, []);

  return (
    <>
      <Box>
        <StaticImage
          layout="fullWidth"
          objectFit="contain"
          src="../../images/header-bg.png"
          alt="beauty-tips-bg"
          loading="lazy"
        />
      </Box>
      <Container maxW={{ lg: "container.md", xl: "container.xl" }} pt={8} pb={40}>
        <SimpleGrid
          mb={{ base: 8, md: -8 }}
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 1, md: 10 }}>
          <Box order={{ base: 2, md: 1 }}>
            <Text fontWeight="bold" fontSize="21px" mb={8}>
              Beauty Tips
            </Text>
            <Text mt={4} fontSize="18px">
              Cari tahu tips dan trick up to date dan seru <br />
              untuk kamu lebih cantik dan sukses
            </Text>
          </Box>
          <Box order={{ base: 1, md: 2 }} textAlign="center">
            <Box
              position="relative"
              bottom={{ base: "80px", md: "120px", lg: "140px", xl: "160px" }}>
              <StaticImage
                layout="constrained"
                objectFit="contain"
                src="../../images/header-product.png"
                alt="beauty-tips-product"
                loading="lazy"
              />
            </Box>
          </Box>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ base: 4, md: 1 }}>
          {!loading ? (
            <>
              {contentList.length &&
                contentList.map((content, idx) => (
                  <Box px={2} mb={4} key={idx}>
                    <Link to={`/beauty-tips/article/${content.slug}`}>
                      <Image
                        src={content.imgWebp || content.img}
                        fallbackSrc={content.img}
                        alt={content?.slug || `tips-${idx}`}
                        objectFit="contain"
                        loading="lazy"
                        m={"0 auto"}
                        w={{ base: "320px", md: "auto" }}
                        maxH="360px"
                        transform="scale(1)"
                        transition="transform 500ms"
                        _hover={{ transform: "scale(1.02)", transition: "transform 500ms" }}
                      />
                    </Link>
                  </Box>
                ))}
            </>
          ) : (
            <>
              {skeletonCount.map(skeleton => {
                return (
                  <Box
                    key={skeleton}
                    margin={"0 auto"}
                    mb={4}
                    h={{ base: "420px", md: "200px", xl: "360px" }}
                    w={{ base: "320px", md: "150px", xl: "270px" }}>
                    <Skeleton height="100%" width="100%" />
                  </Box>
                );
              })}
            </>
          )}
        </SimpleGrid>
        <Center mt={10}>
          <Pagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            onPageChange={setCurrentPage}>
            <PaginationContainer>
              <PaginationPrevious
                p={0}
                _focus={{ boxShadow: "none" }}
                bgColor="transparent"
                rounded="full">
                <FaChevronCircleLeft size={16} />
              </PaginationPrevious>
              <PaginationPageGroup>
                {pages.map(page => (
                  <PaginationPage
                    bg="transparent"
                    _current={{ bg: "gray.200" }}
                    _focus={{ boxShadow: "none" }}
                    key={`pagination_page_${page}`}
                    page={page}
                  />
                ))}
              </PaginationPageGroup>
              <PaginationNext
                p={0}
                _focus={{ boxShadow: "none" }}
                bgColor="transparent"
                rounded="full">
                <FaChevronCircleRight size={16} />
              </PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Center>
      </Container>
    </>
  );
};

export default BeautyTips;
