import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import BeautyTipsComponent from "../../components/beauty-tips/BeautyTips";

const BeautyTips = () => {
  return (
    <Layout>
      <Seo title="Beauty Tips OMG" />
      <BeautyTipsComponent />
    </Layout>
  );
};

export default BeautyTips;
